<template>
	<div class="page">
		<div class="block30"></div>
		<!-- 地理产品 -->
		<div class="huaxue_proDockSchool_page landmarkProducts_box relative w1240">
			<div class="analysis_sys_category_con">
				<div class="analysis_sys_category_title fontsize18">筛选项</div>
				<div class="analysis_sys_category_list">
					<div class="ul list1">
						<div class="li item1" :class="{'isopen':item1.isopen}" v-for="(item1,index1) in industryType" :key="index1">
							<div class="menu_title fontsize16">
								<span class="span" :class="{'hoverspan':industryIndex==index1}" @click="handleindustryType(index1,-1)">{{item1.name}}</span>
								<div class="img" v-if="item1.children&&item1.children.length>0" @click="openindustryType(index1,-1)">
									<img v-if="!item1.isopen" src="../assets/static/icon_add.png"/>
									<img v-if="item1.isopen" src="../assets/static/icon_reduce.png"/>
								</div>
							</div>
							<div ref="list2" class="ul list2 collapse-transition" v-show="item1.children&&item1.children.length>0">
								<div class="li item2" :class="{'isopen':item2.isopen}" v-for="(item2,index2) in item1.children" :key="index2">
									<div class="menu_title fontsize14 menu2_title">
										<span class="span" :class="{'hoverspan':(index1==2&&industrySecIndex==index2) || (index1==1&&industrySecCityIndex==index2)}" @click="handleindustryType(index1,index2)">{{item2.name}}</span>
										<div class="img" v-if="item2.children&&item2.children.length>0" @click="openindustryType(index1,index2)">
											<img v-if="!item2.isopen" src="../assets/static/icon_add.png"/>
											<img v-if="item2.isopen" src="../assets/static/icon_reduce.png"/>
										</div>
									</div>
									<div :ref="'list3_'+index1+'_'+index2" class="ul list3 collapse-transition" v-show="item2.children&&item2.children.length>0">
										<div class="li item3" :class="{'isopen':item3.isopen}" v-for="(item3,index3) in item2.children" :key="index3">
											<div class="menu_title fontsize14 menu3_title">
												<span class="span">{{item3.name}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 地标产品 -->
			<div class="landmarkprocuct_page_con">
				<div class="productList_datalist">
					<div class="title_box">
						<div class="text1 fontsize24">地理标志保护产品统计</div>
						<div class="ml-auto"></div>
						<div class="search_box1 fontsize16">
							<div class="text fontsize16">名称</div>
							<div class="search_box">
								<div class="inputdiv">
									<input v-model="formobj.title" class="fontsize16" placeholder="请输入搜索内容"/>
								</div>
								<div class="searchbtn" @click="handlePComfirm">搜索</div>
							</div>
						</div>
					</div>
					<div class="thlist fontsize16">
						<div class="th1">序号</div>
						<div class="th2">名称</div>
						<div class="th3">地区</div>
						<div class="th4">注册时间</div>
						<div class="th5">所属类型</div>
					</div>
					<div class="tdlist">
						<div class="tditem fontsize16" v-for="(item,index) in ProductList" :key="index" @click="$util.routerPath('/landmarkDetail',{uid:item.id})">
							<div class="td td1">{{item.index}}</div>
							<div class="td td2">{{item.title}}</div>
							<div class="td td3">{{item.province}}{{item.city}}</div>
							<div class="td td4">{{item.registDate}}</div>
							<div class="td td5">{{item.registType}}</div>
						</div>
					</div>
				</div>
				<div class="block30" v-if="ProductList.length<=0"></div>
				<div class="emptyText fontsize16" v-if="ProductList.length<=0">暂无相关记录~</div>
				<div class="block30"></div>
				<div class="phonenone" style="text-align:right">
				    <el-pagination
				          @current-change="handlePCurrentChange"
				          :current-page.sync="pcurrentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next, jumper"
				          :total="ptotal">
				    </el-pagination>
				</div>
				<div class="pcnone" style="text-align:right">
				    <el-pagination
					      small
				          @current-change="handlePCurrentChange"
				          :current-page.sync="pcurrentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next"
				          :total="ptotal">
				    </el-pagination>
				</div>
			</div>
		</div>
		<div class="block58"></div>
		<!-- 地理商标 -->
		<div class="huaxue_proDockSchool_page landmarkProducts_box relative w1240">
			<div class="analysis_sys_category_con">
				<div class="analysis_sys_category_title fontsize18">筛选项</div>
				<div class="analysis_sys_category_list">
					<div class="ul list1">
						<div class="li item1" :class="{'isopen':item1.isopen}" v-for="(item1,index1) in iconindustryType" :key="index1">
							<div class="menu_title fontsize16">
								<span class="span" :class="{'hoverspan':iconindustryIndex==index1}" @click="iconhandleindustryType(index1,-1)">{{item1.name}}</span>
								<div class="img" v-if="item1.children&&item1.children.length>0" @click="iconopenindustryType(index1,-1)">
									<img v-if="!item1.isopen" src="../assets/static/icon_add.png"/>
									<img v-if="item1.isopen" src="../assets/static/icon_reduce.png"/>
								</div>
							</div>
							<div ref="iconlist2" class="ul list2 collapse-transition" v-show="item1.children&&item1.children.length>0">
								<div class="li item2" :class="{'isopen':item2.isopen}" v-for="(item2,index2) in item1.children" :key="index2">
									<div class="menu_title fontsize14 menu2_title">
										<span class="span" :class="{'hoverspan':(index1==2&&iconindustrySecIndex==index2) || (index1==1&&iconindustrySecCityIndex==index2)}" @click="iconhandleindustryType(index1,index2)">{{item2.name}}</span>
										<div class="img" v-if="item2.children&&item2.children.length>0" @click="iconopenindustryType(index1,index2)">
											<img v-if="!item2.isopen" src="../assets/static/icon_add.png"/>
											<img v-if="item2.isopen" src="../assets/static/icon_reduce.png"/>
										</div>
									</div>
									<div :ref="'iconlist3_'+index1+'_'+index2" class="ul list3 collapse-transition" v-show="item2.children&&item2.children.length>0">
										<div class="li item3" :class="{'isopen':item3.isopen}" v-for="(item3,index3) in item2.children" :key="index3">
											<div class="menu_title fontsize14 menu3_title">
												<span class="span">{{item3.name}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 地标商标列表 -->
			<div class="landmarkprocuct_page_con">
				<!-- 地标商标 -->
				<div class="trademarkList_datalist">
					<div class="title_box">
						<div class="text1 fontsize24">地理标志商标统计</div>
						<div class="ml-auto"></div>
						<div class="search_box1 fontsize16">
							<div class="text fontsize16">名称</div>
							<div class="search_box">
								<div class="inputdiv">
									<input v-model="iconformobj.title" class="fontsize16" placeholder="请输入搜索内容"/>
								</div>
								<div class="searchbtn" @click="handleSComfirm">搜索</div>
							</div>
						</div>
					</div>
					<div class="thlist fontsize16">
						<div class="th1">序号</div>
						<div class="th2">名称</div>
						<div class="th3">商标注册人</div>
						<div class="th4">注册证号</div>
						<div class="th5">地区</div>
						<div class="th6">核定使用商品</div>
						<div class="th7">所属类型</div>
						<div class="th8">注册时间</div>
					</div>
					<div class="tdlist">
						<div class="tditem fontsize16" v-for="(item,index) in TrademarkList" :key="index">
							<div class="td td1">{{item.index}}</div>
							<div class="td td2">{{item.title}}</div>
							<div class="td td3">{{item.registrant}}</div>
							<div class="td td4">{{item.registNo}}</div>
							<div class="td td5">{{item.province}}{{item.city}}</div>
							<div class="td td6">{{item.approvedUse}}</div>
							<div class="td td7">{{item.registType}}</div>
							<div class="td td8">{{item.registDate}}</div>
						</div>
					</div>
				</div>
				<div class="block30" v-if="TrademarkList.length<=0"></div>
				<div class="emptyText fontsize16" v-if="TrademarkList.length<=0">暂无相关记录~</div>
				<div class="block30"></div>
				<div class="phonenone" style="text-align:right">
				    <el-pagination
				          @current-change="handleSCurrentChange"
				          :current-page.sync="scurrentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next, jumper"
				          :total="stotal">
				    </el-pagination>
				</div>
				<div class="pcnone" style="text-align:right">
				    <el-pagination
					      small
				          @current-change="handleSCurrentChange"
				          :current-page.sync="scurrentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next"
				          :total="stotal">
				    </el-pagination>
				</div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			dataList:[],
			ProductList:[],//地标产品
			TrademarkList:[],//地标商标
			pcurrentPage:1,
			scurrentPage:1,
			pageSize:10,
			ptotal:0,
			stotal:0,
			showType:"line",//展示形式，行列 非图文
			industryType:[
				{
					name:"全部不限",
					isopen:false,
					children:[],
				},
				{
					name:"地区(广东省)",
					isopen:false,
					children:[],
				},
				{
					name:"获批时间(年份)",
					isopen:false,
					children:[],
				}
			],
			industryIndex:0,//下标
			industrySecCityIndex:-1,//二级选中下标产品
			industrySecIndex:-1,//二级选中下标
			liConHeight: 0, // 折叠面板内容初始高度
			liConHeight3: 0, // 折叠面板内容初始高度
			formobj:{
				city:null,
				year:null,
				title:"",
			},
			iconformobj:{
				city:null,
				year:null,
				title:"",
			},
			iconindustryType:[
				{
					name:"全部不限",
					isopen:false,
					children:[],
				},
				{
					name:"地区(广东省)",
					isopen:false,
					children:[],
				},
				{
					name:"获批时间(年份)",
					isopen:false,
					children:[],
				}
			],
			iconindustryIndex:0,//下标
			iconindustrySecCityIndex:-1,//二级选中下标产品
			iconindustrySecIndex:-1,//二级选中下标
			iconliConHeight: 0, // 折叠面板内容初始高度
			iconliConHeight3: 0, // 折叠面板内容初始高度
		};
	},
	mounted: function() {
		if(!this.isLogin){
			this.$router.push({
				path: "/login"
			});
			return;
		}
		//产品获取地区、年份
		this.getProvinceCity()
		this.getYear()
		this.handlePCurrentChange(1)
		//商标获取地区、年份
		this.icongetProvinceCity()
		this.icongetYear()
		this.handleSCurrentChange(1)
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//点产品击行业分类
		handleindustryType(index1,index2){
			if(this.industryType[index1].children.length>0&&index2<0){
				//console.log("存在二级，不可点击")
				this.openindustryType(index1,index2)
				return;
			}else{
				if(index1==0){
					this.industryIndex = index1
					this.industrySecCityIndex = -1
					this.industrySecIndex = -1
					//点击不限
					this.formobj = {
						city:null,
						year:null,
						ptitle:"",
						stitle:"",
					}
					this.handlePCurrentChange(1)
				}else{
					this.industryIndex = index1
					if(index1==1){
						//地区
						this.industrySecCityIndex = index2
						this.formobj.city = this.industryType[index1].children[index2].name
					}else{
						this.industrySecIndex = index2
						this.formobj.year = this.industryType[index1].children[index2].name
					}
					this.handlePCurrentChange(1)
				}
			}
		},
		//产品展开行业分类
		openindustryType(index1,index2){
			//console.log(index1,index2)
			if(index2!=null&&index2>-1){
				//点击二级
				const isopen = this.industryType[index1].children[index2].isopen
				this.industryType[index1].children[index2].isopen = !isopen
				const liCon3 = this.$refs[`list3_${index1}_${index2}`][0]
				var height3 = liCon3.offsetHeight
				if (height3 === this.liConHeight3) { // 展开
				    liCon3.style.height = 'auto'
				    height3 = liCon3.offsetHeight
				    liCon3.style.height = this.liConHeight3 + 'px'
				    // eslint-disable-next-line no-unused-vars
				    let f = document.body.offsetHeight // 必加
				    liCon3.style.height = height3 + 'px'
				} else { // 收缩
				    liCon3.style.height = this.liConHeight3 + 'px'
				}
			}else{
				//点击一级
				const isopen = this.industryType[index1].isopen
				this.industryType[index1].isopen = !isopen
				//console.log(this.$refs.list2)
				const liCon = this.$refs.list2[index1]
				var height = liCon.offsetHeight
				//console.log(height)
				if (height === this.liConHeight) { // 展开
				    liCon.style.height = 'auto'
				    height = liCon.offsetHeight
				    liCon.style.height = this.liConHeight + 'px'
				    // eslint-disable-next-line no-unused-vars
				    const f = document.body.offsetHeight // 必加
				    liCon.style.height = height + 'px'
					setTimeout(function(){
						liCon.style.height = 'auto'
					},300)
				} else { // 收缩
				    console.log("收缩")
					if(height>0){
						liCon.style.height = height + 'px'
					}
					const f = document.body.offsetHeight // 必加
				    liCon.style.height = this.liConHeight + 'px'
				}
			}
		},
		//商标点击行业分类
		iconhandleindustryType(index1,index2){
			if(this.iconindustryType[index1].children.length>0&&index2<0){
				//console.log("存在二级，不可点击")
				this.iconopenindustryType(index1,index2)
				return;
			}else{
				if(index1==0){
					this.iconindustryIndex = index1
					this.iconindustrySecCityIndex = -1
					this.iconindustrySecIndex = -1
					//点击不限
					this.iconformobj = {
						city:null,
						year:null,
						title:"",
					}
					this.handleSCurrentChange(1)
				}else{
					this.iconindustryIndex = index1
					if(index1==1){
						//地区
						this.iconindustrySecCityIndex = index2
						this.iconformobj.city = this.iconindustryType[index1].children[index2].name
					}else{
						this.iconindustrySecIndex = index2
						this.iconformobj.year = this.iconindustryType[index1].children[index2].name
					}
					this.handleSCurrentChange(1)
				}
			}
		},
		//商标展开行业分类
		iconopenindustryType(index1,index2){
			//console.log(index1,index2)
			if(index2!=null&&index2>-1){
				//点击二级
				const isopen = this.iconindustryType[index1].children[index2].isopen
				this.iconindustryType[index1].children[index2].isopen = !isopen
				const liCon3 = this.$refs[`iconlist3_${index1}_${index2}`][0]
				var height3 = liCon3.offsetHeight
				if (height3 === this.iconliConHeight3) { // 展开
				    liCon3.style.height = 'auto'
				    height3 = liCon3.offsetHeight
				    liCon3.style.height = this.iconliConHeight3 + 'px'
				    // eslint-disable-next-line no-unused-vars
				    let f = document.body.offsetHeight // 必加
				    liCon3.style.height = height3 + 'px'
				} else { // 收缩
				    liCon3.style.height = this.iconliConHeight3 + 'px'
				}
			}else{
				//点击一级
				const isopen = this.iconindustryType[index1].isopen
				this.iconindustryType[index1].isopen = !isopen
				//console.log(this.$refs.list2)
				const liCon = this.$refs.iconlist2[index1]
				var height = liCon.offsetHeight
				//console.log(height)
				if (height === this.iconliConHeight) { // 展开
				    liCon.style.height = 'auto'
				    height = liCon.offsetHeight
				    liCon.style.height = this.iconliConHeight + 'px'
				    // eslint-disable-next-line no-unused-vars
				    const f = document.body.offsetHeight // 必加
				    liCon.style.height = height + 'px'
					setTimeout(function(){
						liCon.style.height = 'auto'
					},300)
				} else { // 收缩
				    console.log("收缩")
					if(height>0){
						liCon.style.height = height + 'px'
					}
					const f = document.body.offsetHeight // 必加
				    liCon.style.height = this.iconliConHeight + 'px'
				}
			}
		},
		//跳转页面
		handlePCurrentChange(currentPage){
			console.log(currentPage)
			this.pcurrentPage = currentPage
			this.getProductdataList()
		},
		handlePComfirm(){
			this.handlePCurrentChange(1)
		},
		//跳转页面
		handleSCurrentChange(currentPage){
			console.log(currentPage)
			this.scurrentPage = currentPage
			this.getTrademarkdataList()
		},
		//商标
		handleSComfirm(){
			this.handleSCurrentChange(1)
		},
		//产品获取地区
		getProvinceCity() {
			var _this = this;
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 88
			this.$http.post('trademarkModule/landmark-products/getProvinceCity', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data[0].cityList;
					var alist = []
					records.forEach((item,index)=>{
						var obj = {
							name:item,
							isopen:false,
							children:[],
						}
						alist.push(obj)
					})
					_this.industryType[1].children = alist
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//产品获取地区
		getYear() {
			var _this = this;
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 88
			this.$http.post('trademarkModule/landmark-products/getYear', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data;
					var ylist = []
					records.forEach((item,index)=>{
						var obj = {
							name:item,
							isopen:false,
							children:[],
						}
						ylist.push(obj)
					})
					_this.industryType[2].children = ylist
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取地区
		icongetProvinceCity() {
			var _this = this;
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 88
			this.$http.post('trademarkModule/landmark-trademark/getProvinceCity', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data[0].cityList;
					var alist = []
					records.forEach((item,index)=>{
						var obj = {
							name:item,
							isopen:false,
							children:[],
						}
						alist.push(obj)
					})
					_this.iconindustryType[1].children = alist
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//商标获取地区
		icongetYear() {
			var _this = this;
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 88
			this.$http.post('trademarkModule/landmark-trademark/getYear', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data;
					var ylist = []
					records.forEach((item,index)=>{
						var obj = {
							name:item,
							isopen:false,
							children:[],
						}
						ylist.push(obj)
					})
					_this.iconindustryType[2].children = ylist
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取地标产品数据
		getProductdataList() {
			var _this = this;
			var params = this.formobj
			params["currentPage"] = this.pcurrentPage
			params["pageSize"] = this.pageSize
			console.log(params)
			this.$http.post('trademarkModule/landmark-products/page', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						// if(item.contents){
						// 	item["body"] = _this.$util.filtersText(item.contents,318)
						// 	item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						// }
						item["index"] = (_this.pcurrentPage-1)*_this.pageSize + (index+1)
					})
					_this.ptotal = res.data.total
					_this.ProductList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		getTrademarkdataList(){
			var _this = this;
			var params = this.iconformobj
			params["currentPage"] = this.scurrentPage
			params["pageSize"] = this.pageSize
			console.log(params)
			this.$http.post('trademarkModule/landmark-trademark/page', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						// if(item.contents){
						// 	item["body"] = _this.$util.filtersText(item.contents,318)
						// 	item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						// }
						item["index"] = (_this.scurrentPage-1)*_this.pageSize + (index+1)
					})
					_this.stotal = res.data.total
					_this.TrademarkList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
